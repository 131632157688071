<template>
    <st-filters-pagination
        stateModule="applications/paymentsList"
        :filters="filters"
        :total="total"
        :header="header"
        :moreFilters="false"
        :showErase="false"
        @change="doFilter"
    >
        <template #filters-toolbar>
            <slot name="toolbar"></slot>
        </template>
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { PaymentDetailsModel } from '@/modules/applications/models/payment-details-model';

const { fields } = PaymentDetailsModel;

export default {
    name: 'ApplicationPaymentsListFilter',
    props: {
    },
    data() {
        return {
            filters: [], // use filterSchema in case filters will be used
            fields,
        };
    },
    computed: {
        ...mapGetters({
            record: 'applications/form/record',
            total: 'applications/paymentsList/total'
        }),
        header() {
            return this.$t('APPLICATION.LIST.PAYMENTS_HEADER');
        },
    },
    methods: {
        ...mapActions({
            doFetch: 'applications/paymentsList/doFetch',
        }),
        doFilter(params) {
            this.params = params;
            this.doFetch({ id: this.record.id, params });
        },
        refresh() {
            this.doFilter(this.params);
        },
    },
};
</script>
