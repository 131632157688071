<template>
    <st-page :showHeader="false" class="mt-6">
        <application-payments />
    </st-page>
</template>

<script>
import ApplicationPayments from '@/modules/applications/components/view-application/payments/ApplicationPayments';

export default {
    name: 'ApplicationPaymentsTab',
    components: {
        ApplicationPayments,
    },
}
</script>
