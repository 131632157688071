<template>
    <ApplicationPaymentsListFilter ref="paymentsListFilter">
        <template #toolbar>
            <ApplicationPaymentsToolbar :record="record" @submit="submit" />
        </template>
        <ApplicationPaymentsList />
    </ApplicationPaymentsListFilter>
</template>
<script>
import ApplicationPaymentsList from './ApplicationPaymentsList.vue';
import ApplicationPaymentsListFilter from './ApplicationPaymentsListFilter.vue';
import ApplicationPaymentsToolbar from './ApplicationPaymentsToolbar.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'ApplicationPayments',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapGetters({
            record: 'applications/form/record'
        }),
    },
    methods: {
        submit() {
            this.$refs.paymentsListFilter.refresh();
        },
    },
    components: { ApplicationPaymentsList, ApplicationPaymentsListFilter, ApplicationPaymentsToolbar }
}
</script>
