<template>
    <div>
        <div class="d-flex w-100">
            <st-button
                variant="primary"
                :callback="submit"
                class="mr-6"
            >
                <i class="fas fa-sync"></i>
                {{ $t('APPLICATION.LIST.REFRESH_PAYMENTS_BUTTON') }}
            </st-button>
            <st-button
                v-if="canCreatePaymentLink"
                variant="primary"
                :callback="showCreatePaymentModal"
            >
                <i class="fas fa-credit-card"></i>
                {{ $t("APPLICATION.VIEW.CREATE_PAYMENT_LINK") }}
            </st-button>
        </div>
        <application-create-payment-modal
            ref="create-payment-modal"
            @submit="submit"
        >
        </application-create-payment-modal>
    </div>
</template>

<script>
export default {
    name: 'ApplicationPaymentsToolbar',
    props: {
        record: Object,
    },
    components: {
        ApplicationCreatePaymentModal: () => import("./ApplicationCreatePaymentModal.vue"),
    },
    methods: {
        showCreatePaymentModal() {
            this.$refs['create-payment-modal'].show();
        },
        submit() {
            this.$emit('submit');
        },
    },
    computed: {
        canCreatePaymentLink() {
            return this.record.assigned_staff_user_id;
        },
    },
};
</script>
