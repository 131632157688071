<template>
    <st-data-table
        :items="rows"
        :fields="fields"
        :modelPresenter="presenter"
        responsive="sm"
        hover
        stateModule="applications/paymentsList"
        :loading="pending"
    >
    </st-data-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { PaymentDetailsModel } from '@/modules/applications/models/payment-details-model';

const { fields } = PaymentDetailsModel;

export default {
    name: 'ApplicationPaymentsList',
    data() {
        return {
            presenter: PaymentDetailsModel.presenter,
        };
    },
    computed: {
        ...mapGetters({
            rows: 'applications/paymentsList/rows',
            loading: 'shared/loading',
        }),
        fields() {
            return [
                fields.bt_payment_id.extendField({ customField: true, portalTarget: 'enable-truncate' }),
                fields.created_date.extendField({ customField: true, portalTarget: 'enable-truncate' }),
                fields.amount.extendField({ bold: true }),
                fields.currency,
                fields.description,
                fields.client_user_name,
                fields.client_user_email.extendField({ customField: true, portalTarget: 'enable-truncate' }),
                fields.payment_type.extendField({ labelType: true }),
                fields.status.extendField({ labelType: true }),
            ]
        },
        pending() {
            return this.loading['paymentsList/doFetch'];
        },
    },
}
</script>